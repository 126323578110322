<template>
  <svg
    width="320"
    height="41"
    viewBox="0 0 320 41"
    fill="#333333"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M228.129 7.48104C224.63 1.99495 219.132 0.598496 212.134 0.498749H206.436C202.137 0.498749 198.638 3.9899 198.638 8.17927V40.3976H212.734C218.432 40.3976 223.73 39.1009 227.429 34.313C230.428 30.3231 231.528 25.236 231.528 20.0492C231.528 15.8598 230.628 11.2714 228.129 7.48104ZM221.031 30.7221C218.832 33.3155 214.933 33.4153 211.734 33.4153H206.336V9.27649C206.336 8.27902 207.135 7.48104 208.135 7.48104H212.434C215.933 7.48104 219.831 7.78028 221.731 11.2714C223.23 13.7651 223.53 17.4557 223.53 20.3484C223.63 23.8396 223.43 27.8294 221.031 30.7221Z" />
    <path d="M50.1843 8.27897C50.1843 4.0896 53.5833 0.59845 57.8819 0.59845H76.876V7.58074H59.7813C58.7816 7.58074 57.9819 8.37872 57.9819 9.37619V16.2587H72.7773V22.9418H57.8819V33.6147H76.876V40.597H50.1843V8.27897Z" />
    <path d="M93.4708 15.8598C90.8716 14.8623 86.1731 13.6653 86.1731 10.7727C86.1731 7.78027 89.8719 6.98229 92.2712 6.98229C95.6701 6.98229 100.369 7.88002 103.168 8.87749H104.167V1.89519C99.9688 0.698229 96.07 0 91.7713 0C88.4724 0.099747 85.0734 0.897723 82.5742 2.99241C80.3749 4.88761 78.6754 7.78027 78.6754 10.8724C78.6754 15.1616 81.0747 18.9519 85.1734 20.9469C88.0725 22.3433 91.1715 23.1413 94.1706 24.039C96.5698 24.7373 99.269 26.1337 99.269 28.8269C99.369 33.116 93.7707 33.914 90.6717 33.914C86.6729 33.914 82.7741 32.9165 79.6751 31.8193H78.7754V38.8016C82.6742 40.0983 86.473 40.7965 90.5717 40.7965C94.8704 40.7965 99.369 39.8988 102.768 37.0062C105.167 35.0112 106.667 31.9191 106.667 28.6274C106.667 25.1363 105.267 21.5454 102.168 19.3509C99.5689 17.755 96.4699 16.7575 93.4708 15.8598Z" />
    <path d="M116.863 7.48104V40.3976H124.561V7.48104H135.658V0.498749H105.967V7.48104H116.863Z" />
    <path d="M137.257 40.3976H163.949V33.4153H144.955V22.7423H159.75V16.0593H144.955V9.27649C144.955 8.27902 145.854 7.48104 146.754 7.48104H163.949V0.498749H144.955C140.656 0.498749 137.257 3.9899 137.257 8.17927V40.3976Z" />
    <path d="M187.342 24.7373C188.341 24.438 188.841 24.1388 189.641 23.6401C193.34 21.3459 194.839 17.356 194.939 13.0669C194.939 8.57826 192.94 3.9899 188.841 1.99495C185.642 0.498749 182.543 0.598496 179.044 0.498749H173.846C169.547 0.498749 165.948 3.9899 165.948 8.27902V40.3976H173.646V26.034H179.644L188.841 40.4973H197.338L187.342 24.7373ZM179.544 19.1514H173.646V9.17674C173.646 8.17927 174.446 7.28155 175.445 7.28155H179.244C184.642 7.28155 187.042 8.77775 187.042 13.3661C186.942 17.755 183.243 19.1514 179.544 19.1514Z" />
    <path d="M258.319 25.5353C258.319 27.5302 258.019 29.4254 256.82 31.1211C255.12 33.3155 252.621 34.1135 250.022 34.1135C247.722 34.1135 244.923 33.515 243.324 31.6198C242.024 29.8244 241.824 27.6299 241.824 25.5353V0.598496H234.127V23.1413C234.127 27.0315 234.327 31.2208 236.626 34.6122C239.325 38.8016 244.423 40.996 249.722 40.996C254.32 40.996 259.319 39.4998 262.318 35.51C265.117 31.8193 265.317 27.8294 265.317 23.1413V0.498749H258.319V25.5353Z" />
    <path d="M291.709 0C288.81 0.099747 287.11 2.59342 287.11 5.28659V40.4973H294.108V11.5707L312.602 40.4973H320V0.598482H313.002V27.9292L297.307 3.29165C296.008 1.29671 294.308 0 291.709 0Z" />
    <path d="M28.891 0.498749H21.0934L14.6954 27.8294L7.89753 0.498749H0L9.49703 36.9064C10.0968 39.3004 12.1962 41.0958 14.7954 40.996C17.1946 40.8963 19.094 39.2006 19.5939 37.1059L24.7923 13.9646L30.3905 36.9064C30.9903 39.2006 32.8897 40.996 35.289 40.996C37.7882 41.0958 39.7876 39.3004 40.2874 37.1059L49.6845 0.598496H42.4867L35.5889 28.2284L28.891 0.498749Z" />
    <path d="M276.214 0C272.215 0 271.215 0.99747 271.215 4.98735C271.215 8.97723 272.215 9.9747 276.214 9.9747C280.213 9.9747 281.212 8.97723 281.212 4.98735C281.212 0.99747 280.213 0 276.214 0Z" />
    <path d="M276.214 15.4608C272.215 15.4608 271.215 16.4583 271.215 20.4481C271.215 24.438 272.215 25.4355 276.214 25.4355C280.213 25.4355 281.212 24.438 281.212 20.4481C281.212 16.4583 280.213 15.4608 276.214 15.4608Z" />
    <path d="M276.214 31.0213C272.215 31.0213 271.215 32.0188 271.215 36.0087C271.215 39.9985 272.215 40.996 276.214 40.996C280.213 40.996 281.212 39.9985 281.212 36.0087C281.212 32.0188 280.213 31.0213 276.214 31.0213Z" />
  </svg>
</template>
