<template>
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.5047 5.60928C7.52341 5.60928 5.11872 8.01396 5.11872 10.9952C5.11872 13.9765 7.52341 16.3811 10.5047 16.3811C13.4859 16.3811 15.8906 13.9765 15.8906 10.9952C15.8906 8.01396 13.4859 5.60928 10.5047 5.60928ZM10.5047 14.4968C8.5781 14.4968 7.0031 12.9265 7.0031 10.9952C7.0031 9.06396 8.57341 7.49365 10.5047 7.49365C12.4359 7.49365 14.0062 9.06396 14.0062 10.9952C14.0062 12.9265 12.4312 14.4968 10.5047 14.4968ZM17.3672 5.38896C17.3672 6.0874 16.8047 6.64521 16.1109 6.64521C15.4125 6.64521 14.8547 6.08271 14.8547 5.38896C14.8547 4.69521 15.4172 4.13271 16.1109 4.13271C16.8047 4.13271 17.3672 4.69521 17.3672 5.38896ZM20.9343 6.66396C20.8547 4.98115 20.4703 3.49053 19.2375 2.2624C18.0093 1.03428 16.5187 0.649902 14.8359 0.565527C13.1015 0.46709 7.9031 0.46709 6.16872 0.565527C4.4906 0.645215 2.99997 1.02959 1.76716 2.25771C0.53435 3.48584 0.154663 4.97646 0.070288 6.65928C-0.0281494 8.39365 -0.0281494 13.5921 0.070288 15.3265C0.149975 17.0093 0.53435 18.4999 1.76716 19.728C2.99997 20.9561 4.48591 21.3405 6.16872 21.4249C7.9031 21.5233 13.1015 21.5233 14.8359 21.4249C16.5187 21.3452 18.0093 20.9608 19.2375 19.728C20.4656 18.4999 20.85 17.0093 20.9343 15.3265C21.0328 13.5921 21.0328 8.39834 20.9343 6.66396ZM18.6937 17.1874C18.3281 18.1061 17.6203 18.814 16.6968 19.1843C15.314 19.7327 12.0328 19.6061 10.5047 19.6061C8.97653 19.6061 5.6906 19.728 4.31247 19.1843C3.39372 18.8186 2.68591 18.1108 2.3156 17.1874C1.76716 15.8046 1.89372 12.5233 1.89372 10.9952C1.89372 9.46709 1.77185 6.18115 2.3156 4.80303C2.68122 3.88428 3.38904 3.17646 4.31247 2.80615C5.69528 2.25771 8.97653 2.38428 10.5047 2.38428C12.0328 2.38428 15.3187 2.2624 16.6968 2.80615C17.6156 3.17178 18.3234 3.87959 18.6937 4.80303C19.2422 6.18584 19.1156 9.46709 19.1156 10.9952C19.1156 12.5233 19.2422 15.8093 18.6937 17.1874Z" />
  </svg>
</template>
