<template>
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 1.125C0 0.503906 0.503906 0 1.125 0H19.875C20.4984 0 21 0.503906 21 1.125C21 1.74844 20.4984 2.25 19.875 2.25H1.125C0.503906 2.25 0 1.74844 0 1.125ZM0 8.625C0 8.00156 0.503906 7.5 1.125 7.5H12C12.6234 7.5 13.125 8.00156 13.125 8.625C13.125 9.24844 12.6234 9.75 12 9.75H1.125C0.503906 9.75 0 9.24844 0 8.625ZM19.875 17.25H1.125C0.503906 17.25 0 16.7484 0 16.125C0 15.5016 0.503906 15 1.125 15H19.875C20.4984 15 21 15.5016 21 16.125C21 16.7484 20.4984 17.25 19.875 17.25Z"
      fill="#333333"
    />
  </svg>
</template>
