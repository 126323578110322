<template>
  <svg
    width="27"
    height="18"
    viewBox="0 0 27 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M25.7651 2.81639C25.4706 1.7078 24.6032 0.834703 23.5017 0.538406C21.5053 0 13.5 0 13.5 0C13.5 0 5.49466 0 3.4982 0.538406C2.39678 0.83475 1.52931 1.7078 1.23489 2.81639C0.699951 4.82578 0.699951 9.01819 0.699951 9.01819C0.699951 9.01819 0.699951 13.2106 1.23489 15.22C1.52931 16.3286 2.39678 17.1653 3.4982 17.4616C5.49466 18 13.5 18 13.5 18C13.5 18 21.5053 18 23.5017 17.4616C24.6032 17.1653 25.4706 16.3286 25.7651 15.22C26.3 13.2106 26.3 9.01819 26.3 9.01819C26.3 9.01819 26.3 4.82578 25.7651 2.81639ZM10.8818 12.8246V5.2118L17.5727 9.01828L10.8818 12.8246Z" />
  </svg>
</template>
