<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.70062 21H0.346875V6.97971H4.70062V21ZM2.52141 5.06721C1.12922 5.06721 0 3.91408 0 2.52189C9.96468e-09 1.85318 0.265647 1.21185 0.738503 0.738991C1.21136 0.266136 1.85269 0.000488281 2.52141 0.000488281C3.19012 0.000488281 3.83145 0.266136 4.30431 0.738991C4.77716 1.21185 5.04281 1.85318 5.04281 2.52189C5.04281 3.91408 3.91313 5.06721 2.52141 5.06721ZM20.9953 21H16.6509V14.175C16.6509 12.5485 16.6181 10.4625 14.3873 10.4625C12.1237 10.4625 11.7769 12.2297 11.7769 14.0578V21H7.42781V6.97971H11.6034V8.89221H11.6644C12.2456 7.79064 13.6655 6.62814 15.7838 6.62814C20.19 6.62814 21 9.52971 21 13.2985V21H20.9953Z" />
  </svg>
</template>
