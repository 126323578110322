<template>
  <div
    v-if="menuItem.children?.length > 0"
    class="text-sm leading-6 text-white"
    :class="{'border-t border-offGray py-10': !prefix || prefix === '#'}"
  >
    <div
      v-if="menuItem.text"
      class="mb-4 text-base font-semibold"
    >
      {{ menuItem.text }}
    </div>

    <ul class="space-y-6">
      <li
        v-for="item in menuItem.children"
        :key="item.text"
      >
        <NavigationMobileBlock
          :menu-item="item"
          :prefix="getTarget(menuItem.url)"
          @link-clicked="$emit('link-clicked')"
        />
      </li>
    </ul>
  </div>

  <NuxtLink
    v-else
    :to="getTarget(menuItem.url)"
    @click="$emit('link-clicked')"
  >
    {{ menuItem.text }}
  </NuxtLink>
</template>

<script setup lang="ts">
import type {MenuItem} from '~/graphql/graphql';

const props = defineProps<{
  menuItem: MenuItem
  prefix?: string
}>();

defineEmits<{
  (e: 'link-clicked'): void
}>();

const isRoot = computed(() => props.prefix || props.prefix === '#');
const getTarget = (url: string) => (isRoot ? '' : props.prefix) + url;
</script>
